import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import {
  type ErrorResponse,
  Link,
  isRouteErrorResponse,
  useMatches,
  useParams,
  useRouteError,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError } from '@sentry/remix'

type StatusHandler = (info: {
  error: ErrorResponse
  params: Record<string, string | undefined>
}) => ErrorBoundaryProps

export function GeneralErrorBoundary({
  statusHandlers = defaultStatusHandlers,
  className,
}: {
  statusHandlers?: Record<number, StatusHandler>
  className?: string
}) {
  const error = useRouteError()
  const params = useParams()
  captureRemixErrorBoundaryError(error)

  if (typeof document !== 'undefined') {
    console.error(error)
  }

  const errorProps = isRouteErrorResponse(error)
    ? (
        statusHandlers[error.status] ??
        defaultStatusHandlers[error.status] ??
        defaultStatusHandlers[500]
      )({ error, params })
    : defaultStatusHandlers[500]()

  return (
    <div
      className={cn(
        'container flex flex-col items-center justify-center h-full text-center',
        className,
      )}
    >
      <ErrorContent {...errorProps} />
    </div>
  )
}

const defaultStatusHandlers: Record<number, () => ErrorBoundaryProps> = {
  404: () => ({
    status: 404,
    statusText: 'Page not found',
    description:
      "Sorry, we couldn't find the page you're looking for. Please make sure the url is correct. If you think you found a technical problem, please let us know so we can fix it :)",
  }),
  500: () => ({
    status: 500,
    statusText: 'Unexpected error',
    description:
      'Sorry, something went wrong. We are trying to fix this problem. Please try again later. In case this problem continues to persist please report it as a problem.',
  }),
}

type ErrorBoundaryProps = {
  status: number
  statusText: string
  description: string
}

function ErrorContent({ status, statusText, description }: ErrorBoundaryProps) {
  const matches = useMatches()
  const isAuthed = matches.find((match) => match.id === 'routes/_authed')

  return (
    <div className='max-w-4xl'>
      <p className='mb-6 text-muted-foreground'>Error {status}</p>

      <h1 className='font-extrabold text-6xl lg:text-8xl mb-4'>Oops!</h1>
      <h2 className='font-extrabold text-6xl lg:text-8xl mb-6'>{statusText}</h2>

      <p className='mb-6 text-muted-foreground'>{description}</p>

      <div className='flex flex-col md:flex-row items-center justify-center gap-x-6 gap-y-4'>
        <Button className='w-full md:w-min' asChild>
          <Link to={isAuthed ? '/app/home' : '/'}>
            {isAuthed ? 'Go to home' : 'Go to fuse.space'}
          </Link>
        </Button>

        <Button variant='ghost' className='w-full md:w-min' asChild>
          <a
            href='https://blocksurvey.io/product-feedback-2wXbdcgEShWjK1y7Sww6Tw?v=o'
            rel='noreferrer'
            target='_blank'
          >
            Report problem
          </a>
        </Button>
      </div>
    </div>
  )
}
